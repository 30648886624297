import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import ArrowDownIcon from 'assets/icons/arrow-drop-down-down.svg';
import ArrowRightIcon from 'assets/icons/arrow-drop-down-right.svg';
import { tablet } from 'styles/breakpoints';

import { Text } from 'components';

import Layout from 'layouts/Layout';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
`;

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  display: flex;
  flex-direction: column;

  align-items: center;
  @media ${tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 1.5rem 1.5rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #e4e4eb;
  justify-content: space-between;
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 2.375rem;
  font-family: Inter;
  font-weight: 500;

  @media ${tablet} {
    font-weight: 600;
  }
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
  color: 'dark80',
})`
  font-size: 1.125rem;
  transition: color 0.2s ease;
  font-family: Inter;
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.colors.dark60};
  }
  /* fixes large text on ios issue */
  -webkit-text-size-adjust: none;
`;

const List = styled.div`
  margin-top: 3rem;
  max-width: 100%;
  width: 832px;
  div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @media ${tablet} {
    margin-top: 2rem;
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  margin-top: 1.06rem;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const ArrowUp = styled(ArrowRightIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
  rotate: 270deg;
`;

const Answer = styled(Text)`
  padding-bottom: 1.5rem;
  font-family: Inter;
  font-weight: 300;
  -webkit-text-size-adjust: 100%;
`;

const FAQ: FC<PeopleAskUsProps> = () => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const isExpanded = (index: number) => expandedQuestions.includes(index);

  return (
    <Layout>
      <Wrapper>
        <Container>
          <Title>Frequently asked questions</Title>
          <List>
            <QuestionContainer expanded={isExpanded(1)}>
              <TitleContainer onClick={() => handleClick(1)}>
                <Question>How does the Raising Dog app work?</Question>
                {isExpanded(1) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(1)}>
                <div ref={childRef}>
                  <Answer>
                    The Raising Dog app allows you to easily record your mood,
                    energy levels, symptoms, sleep, nutrition, other health
                    metrics, and various factors daily, track your medications
                    intake, and set up reminders.
                  </Answer>
                  <Answer>
                    You can then use the collected data to discover how
                    different factors and habits affect your condition, find out
                    your personal triggers, and identify unique relief methods
                    that makes you feel better.
                  </Answer>
                  <Answer>
                    Significantly increase your overall health & wellbeing with
                    smart changes that will help you reach your full potential.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(2)}>
              <TitleContainer onClick={() => handleClick(2)}>
                <Question>Is the Raising Dog app backed by science?</Question>
                {isExpanded(2) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(2)}>
                <div ref={childRef}>
                  <Answer>
                    Raising Dog app is created together with a team of medical
                    professionals to guarantee the highest quality app for all
                    your health needs.
                  </Answer>
                  <Answer>
                    Raising Dog app was evaluated in a systematic review of
                    health apps in a peer-reviewed{' '}
                    <UnderscoreLink
                      href="https://www.mdpi.com/2673-9259/2/3/16"
                      target="_blank"
                    >
                      MDPI JAL journal
                    </UnderscoreLink>{' '}
                    and is recognized as the{' '}
                    <strong>
                      #1 app for monitoring chronic health conditions.
                    </strong>
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(3)}>
              <TitleContainer onClick={() => handleClick(3)}>
                <Question>
                  What about data privacy and confidentiality?
                </Question>
                {isExpanded(3) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(3)}>
                <div ref={childRef}>
                  <Answer>
                    Your health data in the app is encrypted and cannot be seen
                    by anyone but yourself. You are in complete control of your
                    data and can delete everything at any time from within the
                    app.
                  </Answer>
                  <Answer>
                    Our main priority will always be to help people make healthy
                    decisions - we consider data privacy a top priority and will
                    never breach the trust of our users.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
          </List>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default React.memo(FAQ);

const UnderscoreLink = styled.a`
  text-decoration: underline !important;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  padding-top: 48px;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 416px);
  height: 100%;
  @media ${tablet} {
    z-index: 0;
  }
`;
